<template>
  <div class="flex flex-col justify-center items-center bg-gray-800 h-full min-h-screen">
    <div
      class="
        border border-gray-200
        bg-white
        my-5
        rounded-lg
        items-center
        text-center
        w-4/5
        max-w-sm
        md:max-w-lg
        pb-8
      "
    >
      <div class="px-8" :class="iconStyle.padding">
        <lottie-animation
          :path="iconStyle.iconPath"
          :loop="false"
          :autoPlay="true"
          :speed="0.8"
          :width="iconStyle.width"
        />
      </div>
      <div class="mb-5 text-xl px-8">{{ $route.query.payment_message }}</div>
      <div
        class="mb-6 font-bold px-8"
        v-if="$route.query.payment_status == PAYMENT_STATUS.SUCCESSFUL"
      >
        {{ $t("payment.thank_you.thank_you_payment") }}
      </div>
      <div v-else class="h-8 px-8"></div>
      <div class="py-2 w-full px-8 flex justify-center">
        <app-test-mode-badge :testMode="testMode" />
      </div>
      <div
        class="mb-5 px-8"
        v-for="(item, index) in receipt_items"
        :key="item.title"
      >
        <div class="flex justify-between my-2 space-x-2">
          <span class="font-semibold text-left">{{ item.title }}</span>
          <span class="break-all text-right text-gray-500">{{
            item.value
          }}</span>
        </div>
        <hr
          v-if="index < receipt_items.length - 1"
          class="mt-5 border-b border-gray-100"
        />
      </div>
      <div class="mt-16 px-8">
        <div class="flex justify-center">
          {{ $t("payment.thank_you.paid_to") }}
        </div>
        <hr class="mt-2 border-b border-gray-100" />
        <div
          v-if="$route.query.logo_url != null"
          class="mt-5 grid grid-cols-3 space-x-5 items-center"
        >
          <img class="col-span-1" :src="$route.query.logo_url" />
          <div class="col-span-2 p-3 border text-gray-600 border-gray-300 rounded-md">
            {{ $route.query.company_name }}
          </div>
        </div>
        <div v-else class="mt-5 flex justify-center">
          <div class="p-3 border text-gray-600 border-gray-300 rounded-md">
            <app-text-icon
              :text="$route.query.company_name"
              icon_outline="BriefcaseIcon"
            />
          </div>
        </div>
      </div>
      <div class="mt-16">
        <hr class="border-b border-gray-100 mb-8" />
        <span class="font-medium">
          {{ $t("payment.thank_you.close_window") }}
        </span>
      </div>
    </div>
    <div class="grid grid-cols-2  mb-5 text-sm items-center" >
      <p class="text-white">{{ $t('general.powered_by') }}</p> 
      <app-logo-swipe class="h-5"/>
    </div>
  </div>
</template>

<script>
import PAYMENT_STATUS from "@/utils/const/payment_status";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  components: {
    LottieAnimation,
  },
  data() {
    return {
      PAYMENT_STATUS: PAYMENT_STATUS,
      testMode: false,
    };
  },
  beforeCreate() {
    if (!this.$route.query.attempt_id) window.location.href = "/not-found";
  },
  mounted() {
    if (this.$route.query.test != null && this.$route.query.test == true) {
      this.testMode = true;
    }

    this.$store.commit("businessStore/setTestModePublicPage", this.testMode);
  },
  computed: {
    receipt_items() {
      let query = this.$route.query;
      return [
        {
          title: this.$t("payment.thank_you.payment_id"),
          value: query.payment_id,
        },
        {
          title: this.$t("payment.thank_you.amount"),
          value: this.$formats.currency(
            query.payment_currency,
            query.payment_amount ?? 0.0
          ),
        },
        {
          title: this.$t("payment.thank_you.paid_on"),
          value: query.payment_time,
        },
        {
          title: this.$t("payment.thank_you.payment_for"),
          value: query.title,
        },
        {
          title: this.$t("payment.thank_you.payment_method"),
          value: query.payment_method,
        },
      ];
    },
    iconStyle() {
      let query = parseInt(this.$route.query.payment_status);
      switch (query) {
        case PAYMENT_STATUS.FAILED:
          return {
            iconPath: "lottie/error-lottie.json",
            width: "100",
            padding: "p-8",
          };
        case PAYMENT_STATUS.SUCCESSFUL:
          return {
            iconPath: "lottie/success-lottie.json",
            width: "180",
            padding: "",
          };
        case PAYMENT_STATUS.PENDING:
          return {
            iconPath: "lottie/pending-lottie.json",
            width: "80",
            padding: "p-10",
          };
        default:
          return {
            iconPath: "",
            colorStyle: "",
            padding: "",
          };
      }
    },
  },
};
</script>